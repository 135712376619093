<template>
  <v-dialog data-app persistent @click:outside="onToggle($event)" v-model="opened" scrollable>
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>

      <v-card-text>
        <v-row>
          <v-col>
            Адрес: {{ order.address.street }}, {{ order.address.house }}
            <v-chip small :color="order.address.metroColor ? `#${order.address.metroColor}` : ''">
              {{ order.address.metro }}
            </v-chip>
          </v-col>
          <v-col>
            {{ order.client.text ? `${order.client.text},` : '' }} Сумма заказа:
            {{ order.totalCost ? order.totalCost : '0' }} {{ $store.state.user.city.country.currency }}.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Дата уборки: {{ $root.dateToRus(order.datetimecleanObj.date) }}, {{ order.datetimecleanObj.time }}
          </v-col>
        </v-row>
        <v-row>
          <v-alert
              v-if="!(+order.client.depositStandart)"
              dense
              border="left"
              type="warning"
          >
            Внимание! Это новый клиент, постарайтесь поставить <strong>опытного</strong> клинера с оценкой <strong>от
            4.7</strong>!
          </v-alert>
        </v-row>
        <v-data-table
            v-model="chosenCleaners"
            :headers="headers.filter(h=>h)"
            :items="cleaners"
            item-key="n_id"
            show-select
            class="elevation-1"
            no-data-text="Клинеров нет"
            loading-text="Загрузка..."
            :loading="loadingCleaners"
            :footer-props="{'items-per-page-options':[-1]}"
            disable-sort
        >
          <template v-slot:item="{item, index}">
            <tr :style="item.clientListType === 'favorite' ? 'color: green' :
                    item.clientListType === 'blocked' ? 'opacity: 0.5' : ''"
            >
              <td>
                <v-checkbox
                    color="blue"
                    :disabled="!!item.attachError"
                    v-model="chosenCleaners"
                    :value="item"></v-checkbox>
                <v-alert v-if="item.attachError" style="width: 300px;font-size: 14px;padding: 10px" outlined type="error">{{item.attachError}}</v-alert>
              </td>
              <td>{{ index + 1 }}</td>
              <td @click="$eventBus.$emit('openCleaner', item.n_id)" style="cursor: pointer"><span
                  :style="+item.isNew ? 'color:red' : ''">
                <v-tooltip top v-if="item.clientListType=='favorite'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" size="20" style="margin-right: 5px"
                              color="yellow">mdi-star</v-icon>
                    </template>
                    <span>В избранных у данного клиента</span>
                  </v-tooltip>
                <v-tooltip top v-else-if="item.clientListType=='blocked'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" size="20" style="margin-right: 5px"
                              color="red">mdi-block-helper</v-icon>
                    </template>
                    <span>В черном списке у данного клиента</span>
                  </v-tooltip>
                <v-tooltip top v-if="+item.isNew">
                   <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                    </template>
                    <span>Стажер</span>
                </v-tooltip>
                <span v-else>{{ item.name }}</span>
              </span>
                <div v-if="$vuetify.breakpoint.width < 480" :style="item.worktime.notworking?'color: red':''"
                     style="white-space: nowrap">
                  <v-icon v-if="item.worktime.notworking" size="20" color="red">mdi-alert</v-icon>
                  {{ item.worktime.time_from }} - {{ item.worktime.time_to }}
                </div>
              </td>
              <td v-if="$vuetify.breakpoint.width >= 480" :style="item.worktime.notworking?'color: red':''"
                  style="white-space: nowrap">
                <v-icon v-if="item.worktime.notworking" size="20" color="red">mdi-alert</v-icon>
                {{ item.worktime.time_from }} - {{ item.worktime.time_to }}
              </td>
              <td>{{ item.comment }}</td>
              <td>
                <div v-if="item.orders_count > 0" v-for="(order, index) in item.orders" :key="index">
                  {{ $store.state.cleanTypes[order.type] }}, {{ order.total }}, {{ order.datetimecleanObj.time }}
                </div>
              </td>
              <td><span v-if="item.lastOrder">
                Уборка {{
                  $root.dateToRus(item.lastOrder.info.datetimecleanObj.date)
                }} {{ item.lastOrder.info.totalCost + $store.state.user.city.country.currency }}
              <v-rating
                  style="display: inline-block;"
                  v-model="item.lastOrder.rate"
                  class="rating"
                  readonly
                  size="12"
                  dense
              ></v-rating>
              </span></td>
              <td>
                <v-chip small :color="item.metroColor ? `#${item.metroColor}` : 'transparent'">{{ item.metro }}</v-chip>
                {{ item.metroLine }}
              </td>
              <td>{{ item.address }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onToggle">Готово</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
let initialData = {
  opened: false,
  loadingCleaners: true,
  headers: [
    {text: '№', value: 'n_id',},
    // {text: 'Фото', value: 'photos'},
    {text: 'ФИО', value: 'name'},
    {text: 'Время работы', value: 'worktime.time_from'},
    {text: 'Прим', value: 'comment'},
    {text: 'Заказы в этот день', value: 'orders_count'},
    {text: 'Инфо', value: 'info'},
    {text: 'Метро', value: 'metro'},
    {text: 'Адрес', value: 'address'},
  ],
  cleaners: [],
  chosenCleaners: [],
  order: {address: {}, client: {}, datetimecleanObj: {}},
}
export default {
  name: "Cleaners",

  data() {
    let data = JSON.parse(JSON.stringify(initialData));
    if (this.$vuetify.breakpoint.width < 480) data.headers = data.headers.filter(h => h.value != 'worktime.time_from')
    return data;
  },
  methods: {
    open(params) {
      for (let i in initialData) this[i] = initialData[i];
      for (let i in params) this[i] = params[i];
      this.opened = true;
      this.getCleaners()
    },
    onToggle($event) {
      let cleanersAllowed = Math.ceil(this.order.total / this.$store.state.user.revenuePerCleaner) || 1;
      let cleanersCount = 0;
      console.log({...this.chosenCleaners});
      this.chosenCleaners.forEach(cleaner => {
        if (cleaner && +cleaner.n_id && !(+cleaner.isNew)) cleanersCount++;
      })
      if (this.$store.state.user.city.id == 1 && this.order.discount < 30 && cleanersCount > cleanersAllowed) {
        this.$root.notify(`На этот заказ можно прикрепить только ${cleanersAllowed} клинера (${this.$root.printCost(this.$store.state.user.revenuePerCleaner)} на клинера)`, 'error');
        if ($event && $event.preventDefault) $event.preventDefault();
        return false;
      }
      this.cleaners = [];
      this.opened = false;
      this.$eventBus.$emit('setOrderCleaners', this.chosenCleaners, this.order_id);
      // this.$store.commit('setOrderCleaners', this.chosenCleaners)
    },
    getCleaners() {
      this.$store.state.server.request(`order/workers/${this.order_id}`, {
        datetimecleanObj: this.datetimecleanObj,
        clean_type: this.clean_type,
        client: {n_id: this.order.client.n_id}
      }, (data) => {
        this.cleaners = data.response;
        this.loadingCleaners = false;
        this.chosenCleaners.forEach((item, index) => {
          this.chosenCleaners[index] = this.cleaners.find((item2) => item.n_id === item2.n_id);
        }, () => {
          this.loadingCleaners = false;
        });
      });
    },
  },
}
</script>

<style scoped>

</style>
